.editFilter {
    display: flex;
    justify-content: space-evenly;
    align-items: stretch;
    flex-direction: row;
    overflow-y: scroll;
}

.tabPanel {
    overflow-y: scroll;
}

.main1 {
    overflow-y: auto;
}
